/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';

const NotFoundPage = () => (
  <Layout customSEO>
    <SEO title="404: Not found" pathname="/404" />
    <div
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <p
        sx={{
          color: 'text',
          fontSize: '18vw',
          fontWeight: 'normal',
          my: 4,
        }}
      >
        404
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
